import React from 'react';
import { useEffect, useState } from 'react';
import Linkedin from '../assets/icons/linkedin.png';
import { NavLink } from "react-router-dom";

const ImplementationPartners = () => {
  const [partner, setPartner] = useState([]);


  useEffect(() => {
    const fetchPartners = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}all-partners-list`);
      const data = await response.json();
      setPartner(data.data);
    };

    fetchPartners();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (


    <div className="implementation-partners-page">

    <section className="banner">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="banner-content" data-aos="fade-right">
                        <h1>Implementation Partners</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="id-block">
      <div className="container">
        <div className="row">
          {partner.map(partner => (
            <div className="col-md-4" data-aos="fade-up" key={partner.id}>
              <div className="text-block-impli">
                <div className="text-block-top">
                  <h5 className="hdng-h5">{partner.title}</h5>
                </div>
                <div className="text-block-middle">
                  <div className="text-block-middle-image">
                    <img src={partner.user_pic} alt="Person1" />
                    <div className="logo-op">
                      <img src={partner.site_logo} alt="Web_Logo2" />
                    </div>
                  </div>
                  <div className="text-block-middle-links">
                    <div className="person-about">
                      <h5>{partner.name}</h5>
                      <p><NavLink to={`mailto:${partner.email}`}>{partner.email}</NavLink></p>
                      <p><NavLink to={`tel:${partner.phone}`}>{partner.phone}</NavLink></p>
                    </div>
                    <ul>
                      <li><NavLink to={partner.linkedin_profile} target="_blank "><img src={Linkedin} alt="Linkedin" /></NavLink></li>
                      <li><NavLink to={partner.website_link} target="_blank" rel="noreferrer">{partner.website_link}</NavLink></li>
                    </ul>
                  </div>
                </div>
                <div className="text-block-bottom">
                  <p className="hdng-18">{partner.address}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

    </div>

  );
};

export default ImplementationPartners;
